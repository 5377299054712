.text-section {
  margin: 64px auto;
  text-align: left;
  width: 100%; }
  .text-section h1 {
    font-weight: lighter;
    text-align: right;
    width: 30%;
    padding-right: 32px;
    color: #ef5bdd; }
  .text-section .text-container {
    width: 70%;
    max-width: 500px; }
    .text-section .text-container p {
      margin: 16px 0; }
      .text-section .text-container p:first-child {
        margin-top: 0; }
