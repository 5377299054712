.join-container {
  text-align: center;
  font-size: 1rem; }
  .join-container h1 {
    margin-top: 64px; }

.form {
  width: 25%;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 64px;
  text-align: left; }
  .form .input-container {
    display: block;
    width: 100%;
    margin: 16px 0;
    position: relative; }
    .form .input-container::before {
      content: '';
      display: block;
      position: absolute;
      width: 0px;
      top: 13px;
      left: -18px;
      border: 3px solid #00d1b8;
      border-width: 0px 3px 3px 0px;
      padding: 3px;
      transform: rotate(-45deg); }
    .form .input-container .label {
      display: block;
      margin-bottom: 4px;
      font-size: 22px;
      font-family: "M PLUS Rounded 1c", sans-serif;
      font-weight: lighter; }
    .form .input-container .input-text {
      display: block;
      position: relative;
      width: 100%;
      margin-bottom: 8px;
      outline: none;
      border: none;
      font-size: 16px;
      background: #ebebeb;
      padding: 6px 8px;
      border-radius: 5px; }
      .form .input-container .input-text:focus {
        border-color: #00d1b8; }
  .form .submit-container {
    width: 100%;
    text-align: right; }
    .form .submit-container .button {
      cursor: pointer; }

@media screen and (max-width: 1220px) {
  .form {
    width: 50%; } }

@media screen and (max-width: 740px) {
  .form {
    width: 80%; } }
