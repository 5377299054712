#hero-container {
  color: white;
  position: relative;
  padding: 30px;
  width: 100%; }
  #hero-container .hero-copy {
    position: relative;
    top: 112px; }
    #hero-container .hero-copy .hero-children {
      border-radius: 8px;
      margin: 32px;
      padding: 16px;
      width: 50%; }
      #hero-container .hero-copy .hero-children * {
        margin: 8px 0; }
        #hero-container .hero-copy .hero-children *:first-child {
          margin-top: 0; }
        #hero-container .hero-copy .hero-children *:last-child {
          margin-bottom: 0; }
      #hero-container .hero-copy .hero-children i {
        font-family: "Roboto Slab", serif;
        letter-spacing: -1px; }
    #hero-container .hero-copy .copy {
      background: rgba(0, 0, 0, 0.55); }

@media screen and (max-width: 1020px) {
  .hero-copy {
    top: 50px !important; }
    .hero-copy .hero-children {
      width: 100% !important; }
    .hero-copy .hero-children.spacer {
      display: none !important; } }
