.donate-section {
  text-align: center; }
  .donate-section[inverted='true'] .input-container::before {
    border-color: white !important; }
  .donate-section .donate-amount-container .donate-dollar {
    position: absolute;
    margin-right: 0px;
    font-size: 18px;
    bottom: 14px;
    left: 8px;
    font-weight: bold;
    transform: rotate(12deg);
    z-index: 2;
    color: #494949; }
  .donate-section .donate-amount-container .donate-cents {
    position: relative;
    margin-left: 8px;
    top: 8px; }
  .donate-section .donate-amount-container input {
    padding-left: 24px !important; }
