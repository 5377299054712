.brand-container {
  top: 0;
  white-space: nowrap; }
  .brand-container a {
    display: block;
    width: 100%; }
    .brand-container a .brand-logo {
      display: inline-block;
      position: relative;
      width: 50px;
      top: 1px;
      transition: transform 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55); }
      .brand-container a .brand-logo:hover {
        transform: rotate(180deg); }
    .brand-container a .brand-name {
      display: inline-block;
      margin-left: 16px;
      position: relative;
      top: -1px; }
      .brand-container a .brand-name .community {
        position: relative;
        left: -2px;
        font-weight: normal;
        font-size: 32px;
        line-height: 90%; }
      .brand-container a .brand-name .p-e {
        font-weight: 300;
        font-size: 18px; }

header {
  position: fixed;
  width: 100%;
  z-index: 10;
  color: white;
  background: rgba(8, 8, 8, 0.65);
  padding: 16px 0;
  transition: padding 200ms cubic-bezier(0.77, 0, 0.175, 1); }
  header nav ul {
    list-style: none;
    width: 55%;
    display: flex;
    justify-content: space-between;
    align-items: stretch; }
    header nav ul li {
      text-align: center; }
      header nav ul li a {
        display: block;
        height: 100%;
        padding: 18px;
        transition: padding 200ms cubic-bezier(0.77, 0, 0.175, 1); }
        header nav ul li a span {
          position: relative; }
          header nav ul li a span::after {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            width: 0;
            height: 1px;
            background: white;
            transition: width 200ms cubic-bezier(0.77, 0, 0.175, 1); }
      header nav ul li :hover > span::after {
        width: 100%; }
  header.mini {
    padding: 8px 0; }
    header.mini .brand-container .brand-logo {
      width: 28px;
      top: 1px; }
    header.mini .brand-container .brand-name {
      padding: 0;
      top: -4px;
      line-height: 100% !important; }
      header.mini .brand-container .brand-name .community {
        display: inline-block;
        font-size: 24px; }
      header.mini .brand-container .brand-name .p-e {
        display: inline-block;
        font-size: 24px;
        margin-left: 6px; }
    header.mini ul li a {
      padding: 6px 0; }

@media screen and (max-width: 740px) {
  .nav-hamburger-container span {
    display: inline-block;
    cursor: pointer;
    width: 25px;
    position: relative;
    top: 6px; }
    .nav-hamburger-container span div {
      position: relative;
      width: 100%;
      height: 3px;
      background: white;
      margin-bottom: 6px; }
  .nav-hamburger-container .nav-hamburger {
    position: absolute;
    display: none;
    width: 100%;
    left: 0;
    top: 100%;
    padding: 16px;
    padding-bottom: 32px;
    background: rgba(0, 0, 0, 0.75); }
    .nav-hamburger-container .nav-hamburger ul {
      display: block;
      width: 100%; }
      .nav-hamburger-container .nav-hamburger ul li {
        width: 100% !important;
        display: block;
        text-align: left; }
        .nav-hamburger-container .nav-hamburger ul li a {
          width: 100% !important;
          text-align: left;
          font-size: 2rem;
          padding-left: 32px; } }
