/* This is the master styling class, imported first, and sets up some defaults */
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Roboto Slab", serif; }

html,
body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  scroll-behavior: smooth;
  overflow-x: hidden; }

a,
a:visited,
a:hover,
a:focus,
a:active {
  color: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "M PLUS Rounded 1c", sans-serif; }

.body-header {
  margin: 16px 0; }

.container {
  margin: 0 48px; }

.page.container {
  position: relative;
  top: 88px; }

.flex {
  display: flex; }

.flex-around {
  display: flex;
  justify-content: space-around; }

.flex-between {
  display: flex;
  justify-content: space-between; }

/* * * Paragraph Styles * * */
i,
em,
.light-text {
  font-weight: 300 !important; }

b,
strong,
.heavy-text {
  font-weight: 600; }

.magenta-text {
  color: #ef5bdd; }

.green-text {
  color: #00d1b8; }

.blue-text {
  color: #3142b0; }

.big-text {
  font-size: 56px;
  font-family: "M PLUS Rounded 1c", sans-serif; }

.push-left {
  width: 55%;
  margin-right: 45%; }

.push-right {
  width: 55%;
  margin-left: 45%; }
