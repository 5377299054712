.card-container .img-container {
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: cover;
  margin-bottom: 16px; }

.card-container a {
  color: #3142b0 !important;
  text-decoration: underline; }
  .card-container a:hover {
    text-decoration: none; }
