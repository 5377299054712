#hero-container .hero-bg,
#hero-container .hero-bg-next {
  background-color: #111113;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1; }

#hero-container .hero-bg {
  opacity: 1;
  transition: opacity 1000ms cubic-bezier(0.77, 0, 0.175, 1); }

#hero-container .hero-bg.transition {
  opacity: 0; }
