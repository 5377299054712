.h-list {
  display: flex;
  margin: 64px 0; }
  .h-list .h-list-item {
    position: relative;
    margin: 0 32px;
    margin-bottom: 64px;
    list-style: none;
    width: 30%;
    flex-grow: 1; }
    .h-list .h-list-item:first-child {
      margin-left: 0; }
      .h-list .h-list-item:first-child::after {
        display: none; }
    .h-list .h-list-item:last-child {
      margin-right: 0; }
      .h-list .h-list-item:last-child::after {
        display: none; }
  .h-list .h-list-label {
    line-height: 1.1;
    width: 10%;
    min-width: 120px;
    color: #000; }

@media screen and (max-width: 1220px) {
  .h-list {
    flex-wrap: wrap; }
    .h-list .h-list-label {
      width: 100%; } }

@media screen and (max-width: 740px) {
  .h-list {
    flex-wrap: wrap; }
    .h-list .h-list-label {
      width: 100%; }
    .h-list .h-list-item {
      width: 100%; } }
