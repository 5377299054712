footer {
  background: #231f20;
  color: white;
  padding: 64px 0;
  padding-bottom: 8px;
  font-size: 18px; }
  footer .brand-container {
    width: 10%;
    flex-shrink: 1;
    min-width: 400px; }
  footer .nav-container {
    display: flex;
    width: 20%;
    flex-shrink: 1;
    margin-right: 10%; }
    footer .nav-container ul {
      list-style: none;
      width: 50%; }
      footer .nav-container ul li a {
        text-decoration: underline;
        font-size: 14px; }
        footer .nav-container ul li a:hover {
          text-decoration: none; }
  footer .socials-container {
    width: 20%;
    flex-grow: 1; }
    footer .socials-container .h-list {
      margin: 0; }
    footer .socials-container .h-list-item::after {
      display: none; }
  footer .centered {
    text-align: center;
    font-size: 12px;
    font-family: "M PLUS Rounded 1c", sans-serif;
    margin: 32px 0; }
