.grid-container {
  margin: 64px; }
  .grid-container .grid-label {
    font-weight: lighter;
    margin-bottom: 16px; }
  .grid-container .grid-item-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 65%;
    margin: 0 auto; }
    .grid-container .grid-item-container .grid-item {
      width: calc(32% - 4px);
      background: #00d1b8;
      color: white;
      padding: 16px 32px;
      border-radius: 5px;
      white-space: nowrap;
      margin: 4px 4px;
      cursor: pointer;
      position: relative;
      z-index: 1; }
      .grid-container .grid-item-container .grid-item span {
        position: relative;
        z-index: 3; }
      .grid-container .grid-item-container .grid-item::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        top: 0;
        left: 0;
        background: #231f20;
        opacity: 0;
        z-index: 2; }
      .grid-container .grid-item-container .grid-item:hover::after {
        opacity: 0.1; }
  .grid-container:nth-child(odd) .grid-item {
    background: #ef5bdd; }
  .grid-container:nth-child(4n) .grid-item {
    background: #3142b0; }
  .grid-container:last-child {
    margin-bottom: 64px; }
